.terms-and-conditions-top-container {

  h3 {
    color: #848a84;
  }

  .signature {
    p {
      margin-bottom: 0;
    }
  }

  .terms-en {

font-size:large;

  }
  .terms-ar {
    direction: rtl;
    text-align: right;
    font-size: larger;
  }
}
