.navBar,.footer

{
    box-shadow: rgb(146 146 146) 0px 0px 15px 1px;
    border-radius: 0 0 30px 30px;
    /*background: linear-gradient(to left, #3b8d99, #00ddff8a);*/
    background: linear-gradient(to left, #3b8d99, #ff001366);
}

.footer
{
    border-radius:30px 30px 0 0;
}

.btn-grad {background-image: linear-gradient(to right, #D31027 0%, #EA384D  51%, #D31027  100%)}
.btn-grad {
   padding: 5px 20px;
   text-align: center;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   border-radius: 10px;
   display: block;
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff; 
   text-decoration: none;
 }


 .facebookIcon,.youtubeIcon,.twitterIcon,.instaIcon
 ,.playIcon,.appleIcon
 {
     transition: 0.3s all;
 }

.facebookIcon:hover 
 {
    
    color: #3b5999;
    transform: translate(0px,-3px);
 }

 .youtubeIcon:hover
 {
     color: #ec3b35;
     transform: translate(0px,-3px);
 }

 .twitterIcon:hover 
 {
    
    color: #1DA1F2;
    transform: translate(0px,-3px);
 }

 .instaIcon:hover
 {
     color: #ff979b;
     transform: translate(0px,-3px);
 }
 .playIcon:hover 
 {
    
    color: #72c794;
    transform: translate(0px,-3px);
 }

 .appleIcon:hover
 {
     color: #ccc;
     transform: translate(0px,-3px);
 }

 .navbar-dark .navbar-nav .nav-link

 {
     transition: all 0.3s;
 }

 .navbar-dark .navbar-nav .nav-link:hover

 {
    text-shadow: 0 0 3px #fff;
     transform: translate(0 , -4px);
 }