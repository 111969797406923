.table-des 

{
    text-align: center;
}   

.table-des:hover
{
    cursor: pointer;
}

.table-des thead th

{
    border: none;
    padding: 1em;
    background: #3b8d99;
    color: white;
    border-bottom: 4px solid #813f44c2;
}


.table-des tbody tr td {
    padding: 0.5em;
    border: none;
}


.actionsDiv
{
    display :flex;
    justify-content: center;
    align-items: center;
    
}

.actionsDiv div button
{
    
    padding: 5px 20px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: black;            
    border-radius: 10px;
    display: block;
    box-shadow: 0 2px 10px #7e7777;
    
}


  
  
.load-page
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 73vh;
}
  .load-container
{
    
    display: flex;
}

.Loader
{
  background-image: linear-gradient(
0deg
, #ff001366 0%, #3b8d99 100%);
      width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 3px;
    animation: bounce 1s infinite ease;
}
.Loader:nth-of-type(2)
		 {	
		 	animation-delay: 0.2s}
		 .Loader:nth-of-type(3)
		 {	
		 	animation-delay: 0.4s}

@keyframes bounce
		 {
		 	0%,100%
		 	{opacity:1}
		 	
		 	50%
		 	{transform: translateY(-20px);opacity:0.5}
        
		 }



