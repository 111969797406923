@import url("https://fonts.googleapis.com/css?family=Lato");
$itemSize: 100px;
$fontSize: 40px;
$margin: 10px;
$paddingTop: 10px;
@function makelongshadow($color) {
  $val: 0px 0px $color;
  @for $i from 1 through 200 {
    $val: #{$val}, #{$i}px #{$i}px #{$color};
  }
  @return $val;
}
@mixin long-text-shadow($color) {
  text-shadow: makelongshadow(#236f7b);
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .countdown-item {
    overflow: hidden;
    color: #fff;
    font-size: $fontSize;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: $margin;
    padding-top: $paddingTop;
    position: relative;
    width: $itemSize;
    height: $itemSize;
    background: #3c8d99;
    border-radius: 50%;
    font-weight: bold;
    @include long-text-shadow((#236f7b));
    
    span {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
    }
    
  }
}

