.modal-90w {
  width: 90%;
  max-width: none !important;

  .modal-content .modal-body img
  {
    width: 100%;
  }
}


