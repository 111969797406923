.prize-money-top-container {
  h3 {
    color: #848a84;
    text-decoration: underline;
    margin-bottom: 3rem;
  }
  .signature {
    p {
      margin-bottom: 0;
    }
  }
  .races-prizes-en {
    thead 
    {
      color: white;
    background: linear-gradient( to right ,#85242f,#615662);
    tr th 
      {
        padding: 10px;
      }
    }

    & > div
    {
        margin: 2rem 0px;
    }

  }
  .races-prizes-ar {
    

    thead{
      color: white;
      background: linear-gradient( to left ,#3c8c98,#615662);
  
      tr th 
      {
        padding: 10px;
      }
    }
  
  
    tbody
    {
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
font-family: 'Cairo', sans-serif;
    }

    h4 {
      @import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css);
      font-family: "Droid Arabic Kufi", serif;
      text-align: right;
    }
    & > div {
      margin: 2rem 0px;
      direction: rtl;
      td {
        text-align: right;
      }
    }
  }
  .table
  {
    text-align: center;
  }
}
