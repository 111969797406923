.page-body-container {
  padding: 2%; // for desktop
  @media only screen and (max-width: 600px) {
    padding: 4%; //for phones
  }
}


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&family=Rubik&display=swap');

body
{
  font-family: 'Noto Sans JP', sans-serif;
font-family: 'Rubik', sans-serif;
}
