//Mostly on desktops
.homeImageSliderContainer {
  height: 500px;
  margin-bottom: 70px;
  padding-top: 50px;

  .awssld__wrapper
  {
    box-shadow: 0 0 30px 15px #ccc;
    border-radius: 20px;
  }

  .awssld__bullets button

  {
    width: 10px;
    height: 10px;
    background: #90949c;
  }
  .awssld__bullets .awssld__bullets--active 
  {
    background:#3c8c98;
  }
}

//On small screens
@media only screen and (max-height: 600px) {
  .homeImageSliderContainer {
    height: 200px;
  }
}
