iframe
{
    box-shadow: 0 0 5px 0px #918585;
    border-radius: 10px;
    height: 50vh;

    @media only screen and (max-width: 600px) {
        height: 229px;
      }

}

.col-sm-8
{
    @media only screen and (max-width: 600px) {
        margin-top: 0; //for phones
        margin-bottom: 20px;
      }
}

.accordion 
{
    @media only screen and (max-width: 600px) {
        height: auto;
      }

      ​::-webkit-scrollbar {
        width: 0px;
      }
}

​::-webkit-scrollbar {
    width: 0px;
  }