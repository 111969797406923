.row
{
    justify-content: center;
   .card 
   {
    animation: fadein 1s;
    box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.3s;
   }
}

.card-img-overlay
{
    overflow: hidden;
    transition: all 0.3s;
    background:#080808ab;
    opacity: 0;

    .card-title
    {
    color: white;
    position: absolute;
    bottom: -40px;
    transition: all 0.3s;

    }
}

.card-img-overlay:hover
{ cursor: pointer;
    opacity: 1;

    .card-title
    {
    bottom: 10%;
    }
}

.imgsCards:hover
{
    box-shadow:0 14px 28px #00000040, 0 10px 10px #00000038;
    cursor: pointer;
}

@keyframes fadein 
{
    0%,25%,50%{opacity: 0;}

    100%{opacity: 1;}
}